import classNames from "classnames"
import React, { useEffect } from "react"
import styled from "styled-components"

import CloseIcon from "./Shapes/CloseIcon"
import { ColorV1 } from "../../constants/color"
import { storyblokHorizontalGutterClasses } from "../../constants/spacing"

type Props = {
  open: boolean
  handleClose: () => void
  explicitWidth?: number
  closeIconOutside?: boolean
  closeIconColor?: string
  children: React.ReactNode
}

const ModalContent = styled.div<{ width: number | undefined }>`
  width: ${({ width }) => (width ? `${width}px` : "auto")};
`

const Modal: React.FC<Props> = ({
  open,
  handleClose,
  explicitWidth,
  closeIconOutside = true,
  closeIconColor = "white",
  children,
}) => {
  useEffect(() => {
    const bodyElement = document.getElementsByTagName("body")
    if (open) {
      bodyElement[0].style.overflow = "hidden"
    } else {
      bodyElement[0].style.overflow = "scroll"
    }
  }, [open])

  return (
    <div
      className={classNames(
        storyblokHorizontalGutterClasses,
        "fixed w-full h-full top-0 left-0 z-50 transition-all duration-300 py-20 overflow-y-auto flex",
        open ? "opacity-100" : "opacity-0 pointer-events-none"
      )}
      data-test-id="global-modal"
    >
      <div
        className={
          "fixed w-full h-full top-0 left-0 bg-mine-shaft-dark bg-opacity-80"
        }
        onClick={handleClose}
      />
      <ModalContent
        width={explicitWidth}
        className={"max-w-full relative z-10 m-auto"}
      >
        {children}
        <div
          className={classNames(
            "absolute w-7 h-7 cursor-pointer transition-all duration-300 hover:opacity-70",
            closeIconOutside ? "right-0 -top-10" : "top-5 right-5"
          )}
          onClick={handleClose}
          data-test-id="modal-close-button"
        >
          <CloseIcon color={ColorV1[closeIconColor as ColorV1]} />
        </div>
      </ModalContent>
    </div>
  )
}

export default Modal
