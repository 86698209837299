// MARK: Used in V2
import { useLocation } from "@reach/router"
import classNames from "classnames"
import { StoryblokComponent, storyblokEditable } from "gatsby-source-storyblok"
import React, { useState, useEffect, useCallback, useRef } from "react"
import styled, { css } from "styled-components"

import { getBackgroundColorV1Class } from "../../../../utils/color"
import Modal from "../../../elements/Modal"

import GatsbyStoryBlokImage from "@GatsbyStoryBlokImage"

const Container = styled.div<{
  hasBanner: boolean
  stickOut?: boolean
}>`
  ${(props) =>
    props.hasBanner &&
    css`
      display: grid;
      align-items: center;
      grid-template-columns: minmax(auto, 100%);
      grid-template-rows: ${props.stickOut == false
        ? "225px auto"
        : "auto auto"};
      gap: ${props.stickOut == true ? "20px" : "0px"};
    `}

  @media (min-width: ${({ theme }) => theme.screens.sm}) {
    grid-template-columns: 1fr 1fr;
    gap: 0;
    grid-template-rows: 1fr;
  }
`

const ModalContainer: React.FC<
  Storyblok.BlokProps<Storyblok.ModalContainer>
> = ({ blok }) => {
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const [renderChildren, setRenderChildren] = useState(false)
  const timeoutRef = useRef(0)

  const handleOpen = useCallback(() => {
    window.clearTimeout(timeoutRef.current)
    setRenderChildren(true)
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
    timeoutRef.current = window.setTimeout(() => {
      setRenderChildren(false)
    }, 300)
  }, [])

  useEffect(() => {
    const timeOut = setTimeout(() => {
      const links = document.querySelectorAll(`a[href*="#${blok.id}"]`)

      const onLinkClick = (e: any) => {
        e.preventDefault()
        handleOpen()
      }

      if (links) {
        links.forEach((link) => link.addEventListener("click", onLinkClick))
      }

      return () => {
        if (links) {
          links.forEach((link) =>
            link.removeEventListener("click", onLinkClick)
          )
        }
        clearTimeout(timeOut)
      }
    }, 1000)
  }, [blok.id, handleOpen])

  useEffect(() => {
    if (blok.showOnLoad) {
      handleOpen()
    }
  }, [blok.showOnLoad, handleOpen])

  /**
   * Allows for default open state of modal by use of query params.
   * Value passed into query param to have this modal open by default
   * should be the modal id or be the openCriteria name and value matching
   **/

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const modal_open_query_param = urlParams.get(
      process.env.GATSBY_MODAL_OPEN_QUERY_PARAM || ""
    )

    const queryParamCriteria = blok.autoOpenCriteria?.find(
      (value) => urlParams.get(value.queryParamName) === value.queryParamValue
    )

    if (modal_open_query_param === blok.id || queryParamCriteria) {
      handleOpen()
    }
  }, [location, blok.id, handleOpen, blok.autoOpenCriteria])

  useEffect(() => {
    const timeoutCurrent = timeoutRef.current
    return () => {
      window.clearTimeout(timeoutCurrent)
    }
  }, [])

  const hasBanner = !!blok.bannerImage?.filename

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      explicitWidth={blok.width ? parseInt(blok.width) : undefined}
      closeIconOutside={blok.closeIconOutside}
      closeIconColor={blok.closeIconColor}
    >
      <Container
        className={classNames(
          getBackgroundColorV1Class(blok.backgroundColor),
          { "rounded overflow-hidden": blok.rounded },
          { "overflow-visible": blok.stickOut }
        )}
        hasBanner={hasBanner}
        stickOut={blok.stickOut}
        // @ts-ignore to have more strict typing compared to Storybloks generic object typing
        {...storyblokEditable(blok)}
      >
        {blok.bannerImage?.filename ? (
          <div
            className={classNames(
              getBackgroundColorV1Class(blok.bannerBackgroundColor),
              "flex items-center justify-center rounded-t h-full sm:col-start-2 row-start-1 sm:rounded-r sm:rounded-t-none"
            )}
          >
            <GatsbyStoryBlokImage
              className={classNames(
                blok.stickOut
                  ? "flex flex-col -mt-20 -mb-20 w-4/5 sm:-mr-28 sm:mt-0 sm:mb-0 sm:w-full"
                  : "w-full h-full"
              )}
              image={blok.bannerImage?.filename}
              alt={blok.bannerImage.alt}
              width={
                blok.bannerImageWidth ? parseInt(blok.bannerImageWidth) : 0
              }
              height={
                blok.bannerImageHeight ? parseInt(blok.bannerImageHeight) : 0
              }
              layout="constrained"
            />
          </div>
        ) : null}
        <div className={classNames({ "p-9 sm:p-12": blok.padding })}>
          {renderChildren
            ? blok.content?.map((blok) => (
                <StoryblokComponent key={blok._uid} blok={blok} />
              ))
            : undefined}
        </div>
      </Container>
    </Modal>
  )
}

export default ModalContainer
